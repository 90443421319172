import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from "../../../components/BlogPost";
import Lesson from "../../../components/Lesson";
export const meta = {
  published: false,
  publishedAt: "2019-01-16",
  title: "More Regrets",
  summary: ""
};
export const _frontmatter = {};
const layoutProps = {
  meta,
  _frontmatter
};

const MDXLayout = ({
  children
}) => <BlogPost meta={meta}>{children}</BlogPost>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`👋 I am Max, the technical co-founder of `}<a parentName="p" {...{
        "href": "https://spectrum.chat"
      }}>{`Spectrum`}</a>{`. Spectrum is an `}<a parentName="p" {...{
        "href": "https://github.com/withspectrum/spectrum"
      }}>{`open source`}</a>{` chat app for large online communities and was recently acquired by GitHub. We are a team of three with a predominantly frontend and design background and have worked on it for close to two years.`}</p>
    <h3>{`Regret 1: We Did Not Focus On Our Niche`}</h3>
    <ul>
      <li parentName="ul">{`Wanted to build for our use case, open knowledge communities (e.g. open source)`}</li>
      <li parentName="ul">{`Needed to make money, so built business features`}</li>
      <li parentName="ul">{`Businesses were not interested, and had we focused on our core niche use case we would have grown much faster`}</li>
      <li parentName="ul">{`Easier to raise money if necessary, more satisfying, ultimately more momentum and higher chance at success`}</li>
    </ul>
    <Lesson title="Lesson 1" body="It's better to have 100 true fans who love the product than 10,000 barely interested users." mdxType="Lesson" />
    <h3>{`Regret 2: We Did Not Do Enough Marketing`}</h3>
    <ul>
      <li parentName="ul">{`Spend too much time on building new, shiny features`}</li>
      <li parentName="ul">{`Should have spent more time on marketing, typical engineering mistake`}</li>
      <li parentName="ul">{`Not just social media, but also in-product growth marketing, marketing pages on the website, etc.`}</li>
    </ul>
    <Lesson title="Lesson 2" body="Do way more marketing and sales than you think you need to do." mdxType="Lesson" />
    <h3>{`Regret 2.3: We Did Not Tell Our Story`}</h3>
    <ul>
      <li parentName="ul">{`Branding was good and unique`}</li>
      <li parentName="ul">{`Brand did not have minimum viable personality `}<a parentName="li" {...{
          "href": "https://avc.com/2011/09/minimum-viable-personality/"
        }}>{`https://avc.com/2011/09/minimum-viable-personality/`}</a></li>
      <li parentName="ul">{`It differentiates you from the "big guys" as they cannot have a story as personal as you in most cases`}</li>
      <li parentName="ul">{`The Practical Dev (dev.to) is doing a great job at this`}</li>
    </ul>
    <Lesson title="Lesson 2" body="Tell your story, it differentiates you from the big players in a crowded market" mdxType="Lesson" />
    <h3>{`Regret 2.6: We Did Not Launch Often Enough`}</h3>
    <ul>
      <li parentName="ul">{`Launches are great for marketing, every new release excites customers`}</li>
      <li parentName="ul">{`Shipped tons of stuff without ever announcing it`}</li>
      <li parentName="ul">{`Missed opportunity to showcase a new feature and get more users at the same time`}</li>
      <li parentName="ul">{`Now we are building Changefeed because it was too tedious`}</li>
    </ul>
    <Lesson title="Lesson 3" body="Launch before you think you are ready and then again and again and again." mdxType="Lesson" />
    <h3>{`Regret 3: We Did Not Iterate (Quickly) Enough`}</h3>
    <ul>
      <li parentName="ul">{`Had an early MVP built with minimal technology (Firebase)`}</li>
      <li parentName="ul">{`Saw some tiny traction, thought we needed proper setup for custom functionality`}</li>
    </ul>
    <Lesson title="Lesson 4" body="Stick with the minimum viable technology setup until you feel product-market fit. You will know when you have it." mdxType="Lesson" />
    <h3>{`Regret 4: We Did Not Talk Enough With Users`}</h3>
    <ul>
      <li parentName="ul">{`Went into a hole and built a shiny feature for months (e.g. WYSIWYG editing) without talking to users about their priorities`}</li>
      <li parentName="ul">{`Ended up wasting valuable time and adding a lot of unnessary tech debt`}</li>
    </ul>
    <Lesson title="Lesson 5" body="Talk with your users, they should be your guiding star." mdxType="Lesson" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      